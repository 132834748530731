.referral-modal__title {
  font-size: 26px;
  color: #ff9500;
  font-weight: 600;
  text-align: center;
}

.referral-modal__header {
  border-bottom: 1px solid #3232321a;
  padding: 20px;
}

.referral-modal__content {
  padding: 20px;
}

.referral-modal__description p {
  padding: 0;
  margin: 0;
}

.referral-modal__description {
  margin-top: 10px;
}

.referral-modal__instruction {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
}

.referral-modal__details {
  color: #32323280;
  font-size: 14px;
}

.input-with-copy-container {
  margin-top: 25px;
  position: relative;
}

.referral-modal__input {
  padding-left: 35px;
  height: 40px;
  width: 100%;
}

.placeholder__link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--fan-theme_clubPrimary);
  color: #fff;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  margin-top: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
.placeholder__link:active {
  opacity: 0.8;
}

.icon-copy {
  position: absolute;
  left: 12px;
  top: 30%;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 999;
  transition: transform 0.2s ease;
}

.icon-copy:hover {
  transform: scale(1.2);
}

.icon-copy:active {
  transform: scale(0.9);
}
